import { Card, Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import fileTransferService from '../services/filetransfer.service'
import { FormattedMessage } from 'react-intl'
import Brand from '../components/Brand/Brand'
import Back from '../components/Back/Back'


const FileReceiver = () => {
    const [archivosPendientes, setArchivosPendientes] = useState([])
    const [error, setError] = useState(null)
    const [formData, setFormData] = useState({
        nombreUsuarioReceptor: '',
        nombreUsuarioRemitente: '',
        token: '',
    })
    const [consultaRealizada, setConsultaRealizada] = useState(false)

    const handleInputChange = (e) => {
        const { value, name } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleConsultarArchivos = () => {
        const { nombreUsuarioReceptor, nombreUsuarioRemitente, token } = formData

        if (!nombreUsuarioReceptor || !nombreUsuarioRemitente || !token) {
            setError('Please complete all fields before checking.')
            return
        }

        fileTransferService
                            .obtenerArchivosPendientes(nombreUsuarioReceptor, nombreUsuarioRemitente, token)
                            .then((response) => {
                                setArchivosPendientes(response.data.archivos)
                                setError(null)
                                setConsultaRealizada(true)
                            })
                            .catch((err) => {
                                console.error('Error getting pending files:', err)
                                setArchivosPendientes([])
                                setError('No pending files or invalid token.')
                            })
    }

    return (
        <main className="receiver">
            <Back />
            <Brand />
            <hr />
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col md={6}>
                        <Card>
                            <h2>
                                <FormattedMessage id="receive.title" defaultMessage="Receive Transfer" />
                            </h2>
                            <p>
                                <FormattedMessage
                                    id="receive.text"
                                    defaultMessage="Enter your destination nickname and the sender's nickname to receive the file, when the sender has sent the file." />
                            </p>
                            <form>
                                <label>
                                    <FormattedMessage id="receive.input1" defaultMessage="Nick Destination:" />
                                </label>
                                <input
                                    type="text"
                                    name="nombreUsuarioReceptor"
                                    value={formData.nombreUsuarioReceptor}
                                    onChange={handleInputChange} />

                                <label>
                                    <FormattedMessage id="receive.input2" defaultMessage="Nick Emitter:" />
                                </label>
                                <input
                                    type="text"
                                    name="nombreUsuarioRemitente"
                                    value={formData.nombreUsuarioRemitente}
                                    onChange={handleInputChange} />

                                <label>Token:</label>
                                <input
                                    type="text"
                                    name="token"
                                    value={formData.token}
                                    onChange={handleInputChange} />
                            </form>
                            <button className='check' onClick={handleConsultarArchivos}>
                                <FormattedMessage id="receive.button" defaultMessage="Check Files" />
                            </button>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {error && <p>{error}</p>}
            {consultaRealizada && archivosPendientes.length === 0 && !error && (
                <p>No pending files for this token.</p>
            )}

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {archivosPendientes.map((archivo, index) => (
                    <div className='d-flex flex-column justify-content-center align-items-center'
                        key={`${archivo.token}-${index}`}
                        style={{
                            margin: '1em',
                            borderRadius: '1em',
                            padding: '0.5em',
                            minWidth: '200px',
                            background: '#bc2b2b'
                        }}>
                        <h3>{archivo.originalname}</h3>
                        {archivo.originalname.match(/\.(jpg|jpeg|png|gif)$/) && (
                            <img className='my-4'
                                src={`data:image/jpeg;base64,${archivo.thumbnail}`}
                                alt={archivo.originalname}
                                style={{ maxWidth: '120px', maxHeight: '120px' }} />
                        )}
                        <button className='download'
                            onClick={() => {
                                fileTransferService
                                    .descargarArchivo(archivo.token)
                                    .then((response) => {
                                        const url = window.URL.createObjectURL(new Blob([response.data]))
                                        const link = document.createElement('a')
                                        link.href = url
                                        link.setAttribute('download', archivo.originalname)
                                        document.body.appendChild(link)
                                        link.click()
                                    })
                                    .catch((error) => console.error('Error al descargar archivo:', error))
                            }}>
                            <FormattedMessage id="receive.download" defaultMessage="Download" />
                        </button>
                    </div>
                ))}
            </div>
        </main>
    )
}

export default FileReceiver