import axios from 'axios'

class FileTransferService {
    constructor() {
        this.api = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/base` })

        this.api.interceptors.request.use((config) => {
            const storedToken = localStorage.getItem('authToken')
            if (storedToken) {
                config.headers.Authorization = `Bearer ${storedToken}`
            }
            return config
        })
    }

    enviarArchivos(data, onUploadProgress) {
        return this.api.post('/enviar-archivos', data, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                if (onUploadProgress) {
                    onUploadProgress(progress)
                }
            }
        }).catch(error => {
            console.error('Error sending files:', error)
            throw error
        })
    }    

    obtenerArchivosPendientes(nombreDestinatario, nombreEmisor) {
        return this.api.get('/archivo-pendiente', {
            params: { nombreDestinatario, nombreEmisor }
        }).catch(error => {
            console.error('Error getting pending files:', error)
            throw error
        })
    }

    descargarArchivo(token) {
        return this.api.get(`/recibir-archivo/${token}`, {
            responseType: 'blob',
        }).catch(error => {
            console.error('Error downloading file:', error)
            throw error
        })
    }    
}

const fileTransferService = new FileTransferService()
export default fileTransferService