import { Card, Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import fileTransferService from '../services/filetransfer.service'
import { FormattedMessage } from 'react-intl'
import Brand from '../components/Brand/Brand'
import Back from '../components/Back/Back'


const FileSender = () => {
    const [archivos, setArchivos] = useState([])
    const [progresos, setProgresos] = useState({})
    const [mostrarConfirmacion, setMostrarConfirmacion] = useState(false)
    const [token, setToken] = useState('')
    const [formData, setFormData] = useState({
        nombreEmisor: '',
        nombreDestinatario: '',
        archivosChange: [],
    })

    const handleInputChange = (e) => {
        const { value, name, files } = e.target
        if (name === 'archivosChange') {
            const selectedFiles = Array.from(files).slice(0, 5) // Limita a 5 archivos
            setArchivos(selectedFiles)
            const initialProgresos = selectedFiles.reduce((acc, file) => {
                acc[file.name] = 0
                return acc
            }, {})
            setProgresos(initialProgresos)
            setFormData({ ...formData, archivosChange: selectedFiles })
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const usernamePattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{}':"\\|,.<>/?]).{3,}$/
        if (!usernamePattern.test(formData.nombreEmisor) || !usernamePattern.test(formData.nombreDestinatario)) {
            alert('Nicks must contain letters, numbers, and symbols.')
            return
        }
        if (archivos.length === 0) {
            alert('Please select at least one file.')
            return
        }

        const formDataToSend = new FormData()
        formDataToSend.append('nombreEmisor', formData.nombreEmisor)
        formDataToSend.append('nombreDestinatario', formData.nombreDestinatario)
        archivos.forEach((file) => {
            formDataToSend.append('archivos', file)
        })

        try {
            const response = await fileTransferService.enviarArchivos(formDataToSend, (progress) => {
                const updatedProgresos = { ...progresos }
                archivos.forEach((file) => {
                    updatedProgresos[file.name] = progress
                })
                setProgresos(updatedProgresos)
            })

            setToken(response.data.token)
            setArchivos([])
            setProgresos({})
            setMostrarConfirmacion(true)
        } catch (error) {
            console.error('Error sending files:', error)
        }
    }

    return (
        <main className='sender'>
            <Back />
            <Brand />
            <hr />
            <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col md={6}>
                        <Card>
                            <h2>
                                <FormattedMessage id='send.title1' defaultMessage='Send Transfer' />
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <label>
                                    <FormattedMessage id='send.input1' defaultMessage='Nick Emitter:' />
                                </label>
                                <input type='text' name='nombreEmisor' value={formData.nombreEmisor} onChange={handleInputChange} />
                                <label>
                                    <FormattedMessage id='send.input2' defaultMessage='Nick Destination:' />
                                </label>
                                <input type='text' name='nombreDestinatario' value={formData.nombreDestinatario} onChange={handleInputChange} />
                                <label>
                                    <FormattedMessage id='send.input3' defaultMessage='Select Files:' />
                                </label>
                                <input type='file' name='archivosChange' multiple onChange={handleInputChange} />
                                <button type='submit'>
                                    <FormattedMessage id='send.button' defaultMessage='Send Files' />
                                </button>
                            </form>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {archivos.length > 0 && (
                <div>
                    <h3>
                        <FormattedMessage id='send.progress' defaultMessage='Sending Progress:' />
                    </h3>
                    {archivos.map((file) => (
                        <div key={file.name}>
                            <p>{file.name}</p>
                            <div
                                style={{
                                    border: '1px solid #337ab7',
                                    width: '50%',
                                    height: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                }}>
                                <div style={{ backgroundColor: '#337ab7', width: `${progresos[file.name] || 0}%`, height: '100%' }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {mostrarConfirmacion && (
                <div>
                    <h2>
                        <FormattedMessage id='send.title2' defaultMessage='Transfer completed' />
                    </h2>
                    <p>
                        <FormattedMessage
                            id='send.text'
                            defaultMessage='The file transfer was successful. Provide the following Token or QR code to the recipient' />
                    </p>
                    <p>{`${token}`}</p>
                    <QRCodeCanvas value={token} size={128} />
                </div>
            )}
        </main>
    )
}

export default FileSender